import {gql} from "@apollo/client";

const GET_CANDIDATE_RELEASE = gql`
    query getTag($idCandidateRelease: ID!){
        getReleaseCandidate(id: $idCandidateRelease) {
            name
            created_at
            description
            sprint{
                id
                name
            }
            module{
                id
                name
                product{
                    id
                    name
                }
            }
        }
    }
`;

export default GET_CANDIDATE_RELEASE;
