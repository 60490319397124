import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState } from 'react';

const InputAutoComplete = ({onChange, onReset, options, value, selectProject, 
  loading, nameOptionLabel, ...props}) => {

  return (
    <div>
      <Autocomplete
        noOptionsText={'Item não foi localizado'}
        onChange={onChange}
        loading={loading}
        {...props}
        value={value}
        onReset={onReset}
        fullwidth={true}
        loadingText={value ? 'Aguarde Carregando' : 
          'Digite mais de 2 caracteres para pesquisar'}
        options={options}
        style={{marginBottom: '10px'}}
        getOptionLabel={(option) => option[nameOptionLabel]}
        renderInput={(params) => <TextField {...params} 
        label={props.label || "Itens Localizados"}
        style={{margin: 10}}
          onClick={selectProject}
          onChange={selectProject}
          />}
      />
    </div>
  );
};

export {InputAutoComplete};
