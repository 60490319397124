import React from 'react';
import {Box, Button, ButtonGroup, FormHelperText, Typography} from "@mui/material";
import {TextFieldComponent} from "../../components/TextFieldComponent";
import {ButtonComponent} from "../../components/ButtonComponent";
import {useForm} from "react-hook-form";
import {LoadingSend} from "../../components/LoadingSendComponent";
import {Mutation} from "@apollo/client/react/components";
import DELETE_PERMISSION from "../../querys/deletePermission";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const RemovePermission = ({method, close, refresh, idPermission, email}) => {

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Remoção de permissão
            </Typography>
            <Mutation
                mutation={DELETE_PERMISSION}
            >
                {(deletePermission, {data, loading, error}) => {
                    const onSubmit = (idData) => {
                        return deletePermission({
                            variables: {
                                idPermission: idData
                            }
                        })
                    };
                    if (loading) {
                        return <LoadingSend width={300} heigth={300}/>
                    } else if (error) {
                        return (
                            <>
                                <FormHelperText error={true} style={{textAlign: "center"}}>
                                    Ocorreu o erro {error.message}
                                </FormHelperText>
                                <Typography textAlign={"left"} style={{marginTop: 10, marginBottom: 20}}>
                                    Deseja tentar remover novamente o usuário {email}?
                                </Typography>
                                <ButtonGroup variant="contained" fullWidth={true} aria-label="Basic button group">
                                    <Button color={'error'} onClick={close}>Não</Button>
                                    <Button onClick={() => onSubmit(idPermission)}>Sim</Button>
                                </ButtonGroup>
                            </>
                        )
                    } else if (data) {
                        return (
                            <>
                                Removido com sucesso
                                <br/> <br/>
                                <ButtonComponent onClick={close}>Fechar</ButtonComponent>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <Typography textAlign={"left"} style={{marginTop: 10, marginBottom: 20}}>
                                    Você deseja realmente remover o usuário {email}?
                                </Typography>
                                <ButtonGroup variant="contained" fullWidth={true} aria-label="Basic button group">
                                    <Button color={'error'} onClick={close}>Não</Button>
                                    <Button onClick={() => onSubmit(idPermission)}>Sim</Button>
                                </ButtonGroup>
                            </>
                        )
                    }

                }}
            </Mutation>
        </Box>
    );
};

export {RemovePermission};
