import {gql} from "@apollo/client";

const CREATE_PERMISSION = gql`
    mutation createPermission($access: Boolean!, $email: String!, $productId: ID!){
        createPermission(input: {
            access: $access,
            email: $email
            product_id: $productId
        }){
            id
            user{
                email
            }
        }
    }
`;

export default CREATE_PERMISSION;
