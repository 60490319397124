import React, {useState} from 'react';
import {
    Box, Button,
    FormHelperText,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Modal,
    Skeleton
} from "@mui/material";
import {LinkCustomComponent} from "../../components/LinkCustomComponent";
import GET_PERMISSIONS_FROM_PRODUCT from "../../querys/queryPermissionsFromProduct";
import {Query} from "@apollo/client/react/components";
import {RemovePermission} from "./RemovePermission";

const TabPermissionsData = ({current, index, keyTag, link, newItem, idProduct}) => {
    const [userRemoved, setUserRemoved] = useState();
    const [removeModal, setRemoveModal] = useState(false);

    return (
        <List role="tabpanel"
              hidden={current !== index}
              key={`${index}${keyTag}`}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}>
            <Modal
                open={removeModal}
                onClose={() => setRemoveModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <RemovePermission
                    email={userRemoved?.user?.email}
                    refresh={false}
                    idPermission={userRemoved?.id}
                    close={() => setRemoveModal(false)}/>
            </Modal>
            <Query query={GET_PERMISSIONS_FROM_PRODUCT} variables={{idProduct}}>
                {({data, loading, error}) => {
                    if (loading) {
                        return (
                            <Box sx={{width: '800px'}}>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                            </Box>
                        )
                    } else if (error) {
                        return (
                            <>
                                <FormHelperText error={true} style={{textAlign: "center"}}>
                                    {error.message}
                                </FormHelperText>

                            </>
                        )
                    } else if (data) {
                        return (
                            <>
                                <List role="tabpanel"
                                      hidden={current !== index}
                                      key={`${index}${keyTag}`}
                                      id={`simple-tabpanel-${index}`}
                                      aria-labelledby={`simple-tab-${index}`}>
                                    {
                                        current === index &&
                                        data.getProduct.permissions?.map(permission => {
                                            if (permission.set !== null) {
                                                return (
                                                        <ListItem key={permission.id} disablePadding>
                                                            <ListItemButton>
                                                                <ListItemText primary={permission.user.email}/>
                                                                <Button
                                                                    onClick={() => {
                                                                        setUserRemoved(permission)
                                                                        setRemoveModal(true)
                                                                    }}
                                                                    variant="text">Excluir</Button>
                                                            </ListItemButton>
                                                        </ListItem>
                                                )
                                            }
                                        })
                                    }
                                    <ListItem key={'newModule'} disablePadding onClick={newItem}>
                                        <ListItemButton>
                                            <ListItemText primary={'Novo'} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </>
                        )
                    }
                }}
            </Query>
        </List>
    );
};

export {TabPermissionsData};
