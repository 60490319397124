import storageData from "./storageData";

export default {
  setAuthorization: (token, role) => {
    storageData.setData('token', token);
    storageData.setData('role', role);
    window.location.href = '/products';
  },
  removeAuthorization: () => {
    storageData.removeData('token');
    storageData.removeData('role');
    window.location.href = '/auth';
  }
}
