export default {
    print: (title, content) => {
        let mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>Relatório de Sprint</title>');
        mywindow.document.write('</head><body >')
        mywindow.document.write('<h1>' + title + '</h1>');
        mywindow.document.write(content);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }
}
