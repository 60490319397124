import {gql} from "@apollo/client";

const GET_ALL_USERS = gql`
    query getAllUsers{
        getAllUsers{
            id
            email
            role
        }
    }
`;

export default GET_ALL_USERS;
