import {gql} from "@apollo/client";

const IMPORT_PROJECT = gql`
    mutation importProject($id: String!, $department: departmentOptions!){
        importProject(input: {
            id: $id
            department: $department
        }){
            id
            name
        }
    }
`;

export default IMPORT_PROJECT;
