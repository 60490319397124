import {gql} from "@apollo/client";

const GET_ALL_MODULES_AND_RELEASE = gql`
    query getAllModulesAndReleases($idSprint: ID!){
        getSprint(id: $idSprint) {
            product{
                modules{
                    name
                    id
                    releases(typeReleases: WITHOUT_RELEASE_PRODUCT){
                        id
                        name
                    }
                }
            }
        }
    }
`;

export default GET_ALL_MODULES_AND_RELEASE;
