import { ApolloClient, InMemoryCache } from "@apollo/client";
import env from "../environments/env.json"
import storageData from "./storageData";

const client = new ApolloClient({
  uri: env.url || "http://localhost:3001/graphql",
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${storageData.getData('token')}`
  }
});

export default client;
