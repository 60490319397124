import React, {useState} from 'react';
import {Box, FormHelperText, MenuItem, Typography} from "@mui/material";
import {ButtonComponent} from "../../components/ButtonComponent";
import {InputAutoComplete} from "../../components/InputAutoComplete";
import {useForm} from "react-hook-form";
import {LoadingSend} from "../../components/LoadingSendComponent";
import {Mutation} from "@apollo/client/react/components";
import {SelectComponent} from "../../components/SelectComponent";
import {QuerySelect} from "../../components/QuerySelect";
import SEARCH_PROJECT from "../../querys/searchProject";
import IMPORT_PROJECT from "../../querys/importProject";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ProjectsItems = ({register, setValue, value}) => {
    const [hasLoadedProject, setHasLoadedProject] = useState(false);
    const [querySearch, setQuerySearch] = useState("");
    const [projectList, setProjectList] = useState([]);

    return (
        <QuerySelect query={SEARCH_PROJECT}
            variables={{name: querySearch || ""}}
            setQueryData={(result, refetch) => {
                    setHasLoadedProject(true)
                setProjectList(result?.getProjects)
            }}>
            <InputAutoComplete register={register} name='project' 
            label={'Projeto desejado...'}
                    selectProject={(event => {
                        setQuerySearch(event.target.value)
                    })}
                    onInput={(event, newValue) => {
                    }}
                    onChange={(event, newValue) => setValue(newValue)}
                    loading={hasLoadedProject} value={value} onReset={() => {
                        setProjectList([]);
                        setQuerySearch("");
                        setValue("");
                        setHasLoadedProject(true)
                    }}
                    options={projectList} nameOptionLabel={'title'} />
        </QuerySelect>
    )
}

const InsertProduct = ({close, refresh, typeProject}) => {
    const {register, handleSubmit, watch} = useForm();
    const [projectSelected, setProjectSelected] = useState(null);
    const department = watch("department", "")


    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Localização de projeto 
            </Typography>
            <Mutation
                mutation={IMPORT_PROJECT}
                update={(cache, data) => refresh(cache, data, typeProject)}
            >
                {(insertProduct, {data, loading, error}) => {
                    const onSubmit = data => {
                        return insertProduct({
                            variables: {
                                id: projectSelected?.id,
                                department: data.department
                            }
                        })
                    };
                    if (loading) {
                        return <LoadingSend width={300} heigth={300}/>
                    } else if (error) {
                        return (
                            <>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <ProjectsItems register={register} value={projectSelected} 
                                    setValue={setProjectSelected}/>
                                    <SelectComponent register={register} name='department' 
                                    value={department} label={'Departamento'}>
                                        <MenuItem value={'INOVACAO'}>Inovação</MenuItem>
                                        <MenuItem value={'FSD'}>FSD</MenuItem>
                                        <MenuItem value={'OUTROS'}>Outros</MenuItem>
                                    </SelectComponent>
                                    <ButtonComponent type={'submit'} 
                                        disabled={department == "" || projectSelected == null }>
                                        Utilizar este projeto
                                    </ButtonComponent>
                                </form>
                                <FormHelperText error={true} style={{textAlign: "center"}}>
                                    {error.message}
                                </FormHelperText>
                            </>
                        )
                    } else if (data) {
                        return (
                            <>
                                Cadastrado com sucesso
                                <br/> <br/>
                                <ButtonComponent onClick={close}>Fechar</ButtonComponent>
                            </>
                        )
                    } else {
                        return <form onSubmit={handleSubmit(onSubmit)}>
                            <ProjectsItems register={register} value={projectSelected} 
                                setValue={setProjectSelected} />
                            <SelectComponent register={register} name='department' 
                            value={department} label={'Departamento'}>
                                <MenuItem value={'INOVACAO'}>Inovação</MenuItem>
                                <MenuItem value={'FSD'}>FSD</MenuItem>
                                <MenuItem value={'OUTROS'}>Outros</MenuItem>
                            </SelectComponent>
                            <ButtonComponent type={'submit'} 
                                disabled={department == "" || projectSelected == null }>
                                Utilizar este projeto
                            </ButtonComponent>
                        </form>
                    }

                }}
            </Mutation>
        </Box>
    );
};

export {InsertProduct};
