import React from 'react';
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {LinkCustomComponent} from "../../components/LinkCustomComponent";

const TabSprintDetails = ({data, current, index, keyTag, link, newItem, reference, environment}) => {
    data = keyTag === 'rel' ? [...data, {set: null}] : data;
    let printOption;
    printOption = reference !== null ?
        <LinkCustomComponent key={`${module.id}LinkCustomComponent`} to={`/sprints/${reference}/commits/${environment}`}>
            <ListItem key={module.id} disablePadding>
                <ListItemButton>
                    <ListItemText
                        primary={'Relatório Geral para impressão'}/>
                </ListItemButton>
            </ListItem>
        </LinkCustomComponent> : ('')

    return (
        <>
            <List role="tabpanel"
                  hidden={current !== index}
                  key={`${index}${keyTag}`}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}>
                {printOption}

                {
                    current === index &&
                    data.map(module => {
                        if (module.set !== null) {
                            return (
                                <LinkCustomComponent to={`/${link}/${module.id}`}>
                                    <ListItem key={module.id} disablePadding>
                                        <ListItemButton>
                                            <ListItemText
                                                primary={module.module?.name ? `${module.name} - Módulo ${module.module.name}` : module.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                </LinkCustomComponent>
                            )
                        } else {
                            return (
                                <ListItem key={'newModule'} disablePadding onClick={newItem}>
                                    <ListItemButton>
                                        <ListItemText primary={'Novo'}/>
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    })
                }
            </List>
        </>
    );
};

export {TabSprintDetails};
