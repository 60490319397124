import React, {useState} from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Typography
} from "@mui/material";
import {TextFieldComponent} from "../../components/TextFieldComponent";
import {ButtonComponent} from "../../components/ButtonComponent";
import {useForm} from "react-hook-form";
import {LoadingSend} from "../../components/LoadingSendComponent";
import {Query, Mutation} from "@apollo/client/react/components";
import CREATE_RELEASE from "../../querys/createRelease";
import {Skeleton} from "@mui/lab";
import GET_ALL_MODULES_AND_RELEASE from "../../querys/querySprintAndProductAndModules";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const InsertReleaseProduct = ({close, refresh, idSprint}) => {
    const {register, handleSubmit} = useForm();
    const [releases, setReleases] = useState([]);

    const renderListModules = (dataQuery) => {
        return dataQuery.getSprint.product.modules.map(module => {
            if (module.releases) {
                return module.releases.map((release, key) => {
                    return (
                        <FormControlLabel
                            key={`release${key}`}
                            control={<Checkbox/>}
                            onChange={(event, data) => {
                                if (data === true) {
                                    setReleases([...releases, release.id]);
                                } else {
                                    const indexValue = releases.indexOf(release.id);
                                    releases.splice(indexValue, 1)

                                }
                            }}
                            label={`${release.name} - ${module.name}`}/>
                    )
                })
            } else {
                return <></>
            }
        })
    }

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Cadastro de Release de produto
            </Typography>


            <Query query={GET_ALL_MODULES_AND_RELEASE}
                   variables={{idSprint: idSprint}}>
                {({data: dataQuery, loading: loadingQuery, error: errorQuery}) => {
                    if (loadingQuery) {
                        return (
                            <Box sx={{width: '800px'}}>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                                <Skeleton animation="wave"/>
                            </Box>
                        )
                    } else if (errorQuery) {
                        return (
                            <>
                                <FormHelperText errorQuery={true} style={{textAlign: "center"}}>
                                    {errorQuery.message}
                                </FormHelperText>
                            </>
                        )
                    } else if (dataQuery) {
                        return (
                            <>
                                <Mutation
                                    mutation={CREATE_RELEASE}
                                    update={(cache, {data}) => refresh(cache, data, idSprint)}
                                >
                                    {(insertSprint, {data, loading, error}) => {
                                        const onSubmit = data => {
                                            return insertSprint({
                                                variables: {
                                                    name: data.name,
                                                    sprintId: idSprint,
                                                    releases
                                                }
                                            })
                                        };
                                        if (loading) {
                                            return <LoadingSend width={300} heigth={300}/>
                                        } else if (error) {
                                            return (
                                                <>
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <TextFieldComponent register={register} name='name'
                                                                            label="Nome"/>
                                                        {renderListModules(dataQuery)}
                                                        <ButtonComponent type={'submit'}>Cadastrar</ButtonComponent>
                                                    </form>
                                                    <FormHelperText error={true} style={{textAlign: "center"}}>
                                                        {error.message}
                                                    </FormHelperText>
                                                </>
                                            )
                                        } else if (data) {
                                            return (
                                                <>
                                                    Cadastrado com sucesso
                                                    <br/> <br/>
                                                    <ButtonComponent onClick={close}>Fechar</ButtonComponent>
                                                </>
                                            )
                                        } else {
                                            return <form onSubmit={handleSubmit(onSubmit)}>
                                                <TextFieldComponent register={register} name='name' label="Nome"/>
                                                {renderListModules(dataQuery)}
                                                <ButtonComponent type={'submit'}>Cadastrar</ButtonComponent>
                                            </form>
                                        }

                                    }}
                                </Mutation>

                            </>
                        )
                    }
                }}
            </Query>
        </Box>
    );
};

export {InsertReleaseProduct};
