import React from "react";
import {MenuComponent} from "../../components/MenuComponent";
import {CardComponent} from "../../components/CardComponent";
import {Query} from "@apollo/client/react/components";
import {
    Box,
    FormHelperText,
    Skeleton,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {withRouter} from "../../services/withRouter";
import {TabModuleDetails} from "./TabModuleDetails";
import GET_PRODUCT_AND_TAG_AND_RELEASES from "../../querys/queryProductAndTagAndReleases";
import {TabSprintDetails} from "../Sprint/TabSprintDetails";

function attachProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class ModulesComponentPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0,
            open: false
        };
    }

    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <Query query={GET_PRODUCT_AND_TAG_AND_RELEASES}
                               variables={{idModule: this.props.params.idModule}}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    return (
                                        <>
                                            <Typography variant="h4" component="h5" textAlign={"center"}>
                                                Módulo {data.getModule.name} - {data.getModule.product.name}
                                            </Typography>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                <b>ID:</b> {this.props.params.idModule}
                                            </Typography>
                                            <Tabs onChange={(event, newValue) => this.setState({indexTab: newValue})}
                                                  value={this.state.indexTab}>
                                                <Tab label="Releases" {...attachProps(0)} />
                                                <Tab label="Candidato de releases" {...attachProps(1)} />
                                                <Tab label="Versões" {...attachProps(2)} />
                                            </Tabs>
                                            <TabModuleDetails link={'releases'} keyTag={'rel'}
                                                              current={this.state.indexTab} index={0}
                                                              data={data.getModule.releases}/>
                                            <TabSprintDetails link={'releaseCandidate'} keyTag={'releaseCandidate'} current={this.state.indexTab}
                                                              index={1} reference={null}
                                                              data={data.getModule.releaseCandidate}/>
                                            <TabModuleDetails link={'tags'} keyTag={'tag'} current={this.state.indexTab}
                                                              index={2}
                                                              data={data.getModule.tags}/>
                                        </>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const ModulesComponent = withRouter(ModulesComponentPure);
