import React from "react";
import {MenuComponent} from "../components/MenuComponent";
import {CardComponent} from "../components/CardComponent";
import {Query} from "@apollo/client/react/components";
import {
    Box,
    FormHelperText,
    Skeleton,
    Typography
} from "@mui/material";
import {withRouter} from "../services/withRouter";
import GET_ALL_MODULES_AND_RELEASE_AND_COMMITS from "../querys/querySprintAndProductAndModulesAndCommits";
import emoji from "node-emoji";
import {LinkCustomComponent} from "../components/LinkCustomComponent";
import printService from "../services/printService";

class DetailsCommitComponentPure extends React.Component {
    descriptionGeneral;

    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0,
            openNewRelease: false,
            productId: null
        };
    }

    renderTag(tag) {
        this.descriptionGeneral += `<br/><br/> <b>${tag.module.name}</b> <br/>`;
        let description = 'Sem descrição';
        if (tag.description) {
            description = tag.description.replace(/\*/g, "<br />");
            description = emoji.emojify(description);
        }
        this.descriptionGeneral += `${tag.name} <br/><br/>`;
        this.descriptionGeneral += description;
        return (
            <>
                {tag.module.name} <br/>
                <>
                    <Typography variant="subtitle1"
                                component="subtitle2"
                                textAlign={"left"}>
                    </Typography>
                    <br/>
                    <div style={{
                        backgroundColor: '#F1F3F4',
                        padding: 10
                    }}>
                        <Typography variant="subtitle1"
                                    component="subtitle2"
                                    textAlign={"left"}>
                            <td dangerouslySetInnerHTML={{__html: description}}/>
                        </Typography>
                    </div>
                </>

            </>
        )
    }


    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <Query query={GET_ALL_MODULES_AND_RELEASE_AND_COMMITS}
                               variables={{idSprint: this.props.params.idSprint}}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    let beginDate = new Date(parseInt(data.getSprint.begin));
                                    beginDate = beginDate.toLocaleDateString('pt-BR', {timeZone: 'UTC'});
                                    let endDate = new Date(parseInt(data.getSprint.end));
                                    endDate = endDate.toLocaleDateString('pt-BR', {timeZone: 'UTC'});
                                    this.descriptionGeneral = '';

                                    const env = this.props.params.environmentDev === "Desenvolvimento" ? "tags" : "releaseCandidate";

                                    return (
                                        <>
                                            <Typography variant="h4" component="h5" textAlign={"center"}>
                                                Sprint {data.getSprint.name} - {data.getSprint.product.name}
                                            </Typography>
                                            <div style={{float: 'right'}}>
                                                <LinkCustomComponent to={'./'}
                                                                     onClick={() => printService.print(
                                                                         `${data.getSprint.name}/${this.props.params.environmentDev} - ${data.getSprint.product.name}`,
                                                                         this.descriptionGeneral)}>
                                                    Imprimir
                                                </LinkCustomComponent>
                                            </div>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Início: {beginDate}
                                            </Typography>
                                            <br/>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Fim: {endDate}
                                            </Typography>
                                            <br/>

                                            <br/>
                                            {
                                                data.getSprint[env].map(tag => {
                                                    return this.renderTag(tag);
                                                })
                                            }
                                        </>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const DetailsCommitComponent = withRouter(DetailsCommitComponentPure);
