import {gql} from "@apollo/client";

const CREATE_SPRINT = gql`
    mutation createSprint($name: String!, $productId: ID!, $begin: String!, $end: String!){
        createSprint(input: {
            name: $name
            product_id: $productId
            begin: $begin
            end: $end
        }){
            id
            name
            begin
            end
        }
    }
`;

export default CREATE_SPRINT;
