import {gql} from "@apollo/client";

const GET_ALL_PRODUCT_AND_TAGS_AND_RELEASE_AND_SPRINT = gql`
    query getAllProductAndTagsAndReleaseAndSprint($idSprint: ID!){
        getSprint(id: $idSprint) {
            id
            name
            product{
                id
                name
            }
            releaseCandidate{
                id
                name
            }
            begin
            end
            tags{
                id
                name
                module {
                    name
                }
            }
            productReleases{
                id
                name
            }
        }
    }
`;

export default GET_ALL_PRODUCT_AND_TAGS_AND_RELEASE_AND_SPRINT;
