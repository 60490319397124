import React from 'react';
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {LinkCustomComponent} from "../../components/LinkCustomComponent";

const TabModuleDetails = ({data, current, index, keyTag, link}) => {
    return (
        <List role="tabpanel"
              hidden={current !== index}
              key={`${index}${keyTag}`}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}>
            {
                current === index &&
                data.map(module => {
                    return (
                        <LinkCustomComponent key={`${module.id}LinkCustomComponent`} to={`/${link}/${module.id}`}>
                            <ListItem key={module.id} disablePadding>
                                <ListItemButton>
                                    <ListItemText primary={module.name}/>
                                </ListItemButton>
                            </ListItem>
                        </LinkCustomComponent>
                    )
                })
            }
        </List>
    );
};

export {TabModuleDetails};
