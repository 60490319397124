import React from "react";
import {MenuComponent} from "../components/MenuComponent";
import {CardComponent} from "../components/CardComponent";
import {Query} from "@apollo/client/react/components";
import {
    Box, Chip,
    FormHelperText,
    Skeleton,
    Typography
} from "@mui/material";
import {withRouter} from "../services/withRouter";
import {LinkCustomComponent} from "../components/LinkCustomComponent";
import GET_PRODUCT_RELEASE from "../querys/queryReleaseProduct";
import * as emoji from "node-emoji";
import printService from "../services/printService";

class ProductReleaseComponentPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0
        };
    }

    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <Query query={GET_PRODUCT_RELEASE}
                               variables={{idProductRelease: this.props.params.idProductRelease}}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    let createdAt = new Date(parseInt(data.getProductRelease.created_at));
                                    createdAt = createdAt.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

                                    let product = 'Não informado';
                                    if (data.getProductRelease.sprint) {
                                        product = data.getProductRelease.sprint.product
                                    }

                                    let description = 'Sem descrição';
                                    if (data.getProductRelease.description) {
                                        description = data.getProductRelease.description.replace(/\\n/g, "<br />");
                                        description = description.replace(/\n/g, "<br />");
                                        description = emoji.emojify(description);
                                    }

                                    return (
                                        <>
                                            <Typography variant="h4" component="h5" textAlign={"left"}>
                                                Release {data.getProductRelease.name}
                                            </Typography>
                                            <br/>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Criado em: {createdAt} -
                                                <LinkCustomComponent to={'./'}
                                                                     onClick={() => printService.print(
                                                                         `Versão ${data.getProductRelease.name}`,
                                                                         description)}>
                                                    Imprimir
                                                </LinkCustomComponent>
                                            </Typography>
                                            <br/>
                                            <div style={{backgroundColor: '#F1F3F4', padding: 10}}>
                                                <Typography variant="subtitle1" component="subtitle2"
                                                            textAlign={"left"}>
                                                    <td dangerouslySetInnerHTML={{__html: description}}/>
                                                </Typography>
                                            </div>

                                            <br/><br/>

                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Sprint: <LinkCustomComponent
                                                to={`/sprints/${data.getProductRelease.sprint.id}`}>
                                                {
                                                    data.getProductRelease.sprint ?
                                                        (<Chip clickable={true}
                                                               label={data.getProductRelease.sprint.name}
                                                               color="primary"/>) :
                                                        ('Não definido')
                                                }

                                            </LinkCustomComponent>
                                            </Typography>

                                            <br/>

                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Produto:
                                                <LinkCustomComponent to={`/products/${product.id}`}>
                                                    <Chip clickable={true} label={product.name} color="primary"/>
                                                </LinkCustomComponent>
                                            </Typography>
                                        </>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const ProductReleaseComponent = withRouter(ProductReleaseComponentPure);
