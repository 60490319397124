import React from "react";
import {Mutation} from "@apollo/client/react/components";
import {LogoComponent} from "../../components/LogoComponent";
import CREATE_AUTH from "../../querys/auth";
import {FormLogin} from "./FormLogin";
import {CardComponent} from "../../components/CardComponent";
import {FormHelperText, Typography} from "@mui/material";
import {LoadingSend} from "../../components/LoadingSendComponent";
import authService from "../../services/authService";
import {withRouter} from "../../services/withRouter";

class AuthComponentPure extends React.Component {
    constructor(props) {
        super(props);
    }

    _loginFields(login, {username, password}) {
        return login({
            variables: {
                username, password
            }
        })
    }

    render() {
        return (
            <>
                <header className="content auth-content">
                    <LogoComponent/>

                    <CardComponent>
                        <Typography variant="h4" component="h5" textAlign={"center"}>Sistema de versões</Typography>
                        <Typography variant="h5" component="h6" textAlign={"center"}>Autenticação</Typography>
                        <br/>
                        <Mutation
                            mutation={CREATE_AUTH}
                        >
                            {(login, {data, loading, error}) => {
                                if (loading) {
                                    return <LoadingSend width={300} heigth={300}/>
                                } else if (error) {
                                    return (
                                        <>
                                            <FormLogin sendData={this._loginFields} method={login}/>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>
                                        </>
                                    )
                                } else if (data) {
                                    authService.setAuthorization(data.createToken.token, data.createToken.user.role)
                                } else {
                                    return <FormLogin sendData={this._loginFields} method={login}/>
                                }

                            }}
                        </Mutation>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const AuthComponent = withRouter(AuthComponentPure);
