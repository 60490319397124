import {gql} from "@apollo/client";

const GET_PRODUCT_RELEASE = gql`
    query getProductRelease($idProductRelease: ID!){
        getProductRelease(id: $idProductRelease) {
            name
            created_at
            description
            releases{
                id
                name
                module{
                    name
                }
            }
            sprint {
                id
                name
                product {
                    id
                    name
                }
            }
        }
    }
`;

export default GET_PRODUCT_RELEASE;
