import {gql} from "@apollo/client";

const GET_PRODUCT_AND_TAG_AND_RELEASES = gql`
    query getProductAndTagAndReleases($idModule: ID!){
        getModule(id: $idModule) {
            name
            releaseCandidate{
                id
                name
            }
            product{
                name
            }
            tags{
                id
                name
            }
            releases{
                id
                name
            }
        }
    }
`;

export default GET_PRODUCT_AND_TAG_AND_RELEASES;
