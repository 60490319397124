import React from "react";
import {MenuComponent} from "../../components/MenuComponent";
import {CardComponent} from "../../components/CardComponent";
import {Query} from "@apollo/client/react/components";
import {
    Box,
    Button,
    FormHelperText,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Modal,
    Skeleton,
    Typography,
    MenuItem
} from "@mui/material";
import GET_ALL_PRODUCTS from "../../querys/queryProducts";
import {withRouter} from "../../services/withRouter";
import {LinkCustomComponent} from "../../components/LinkCustomComponent";
import {InsertProduct} from "./InsertProduct";
import storageData from "../../services/storageData";
import { SelectComponent } from "../../components/SelectComponent";
import { CecisComponent } from "../../components/CecisVersion";


class ProductListComponentPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            role: storageData.getData('role'),
            typeProject: null
        }
    }


    refreshCache(cache, data, typeProject) {
        let {getAllProducts} = cache.readQuery({
            query: GET_ALL_PRODUCTS,
            variables:{filterType: typeProject}
        });
        getAllProducts = [{...data.data.importProject}, ...getAllProducts];
        cache.writeQuery({query: GET_ALL_PRODUCTS, data: {getAllProducts}, 
            variables:{filterType: typeProject}});
        return null;
    }

    setNewState(key, value) {
        this.setState({...this.state, [key]: value})
    }

    render() {
        return (
            <>
                <MenuComponent/>

                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <CecisComponent />
                        <br/>
                        <br/>
                        <div style={{minHeight: "80px"}}>
                        <Typography variant="h4" component="h5" textAlign={"left"} style={{float: "right"}}>
                            <SelectComponent name={'tipo'} value={this.state.typeProject} 
                            label={'Tipo de projeto'} style={{width: '200px', margin: '0px'}}
                            onChange={(event) => {
                                this.setNewState('typeProject', event.target.value)
                            }}>
                                <MenuItem value="DESENVOLVIMENTO">Desenvolvimento</MenuItem>
                                <MenuItem value="MANUTENCAO">Manutenção</MenuItem>
                            </SelectComponent>
                        </Typography>
                        <Typography variant="h4" component="h5" textAlign={"left"}>
                            {this.state.role === 'ADMIN' && (
                                <Button onClick={() => this.setNewState('open', true)} variant="text">
                                    Localizar Projeto </Button>
                            )}
                        </Typography>
                        </div>
                        <Modal
                            open={this.state.open}
                            onClose={() => this.setNewState('open', false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <InsertProduct refresh={this.refreshCache} close={() => 
                                this.setNewState('open', false)} typeProject={this.state.typeProject} />
                        </Modal>
                        <Query query={GET_ALL_PRODUCTS} variables={{filterType: this.state.typeProject}}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    return (
                                        <List>
                                            {
                                                data.getAllProducts.map(product => {
                                                    return (
                                                        <LinkCustomComponent 
                                                            key={`${product.id}LinkCustomComponent`} 
                                                            to={`/products/${product.id}`}>
                                                            <ListItem key={product.id} disablePadding>
                                                                <ListItemButton>
                                                                    <ListItemText primary={product.name}/>
                                                                </ListItemButton>
                                                            </ListItem>
                                                        </LinkCustomComponent>
                                                    )
                                                })
                                            }
                                        </List>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const ProductListComponent = withRouter(ProductListComponentPure);
