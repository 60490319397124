import React from "react";
import {MenuComponent} from "../../components/MenuComponent";
import {CardComponent} from "../../components/CardComponent";
import {Query} from "@apollo/client/react/components";
import {
    Box,
    FormHelperText,
    Modal,
    Skeleton,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {withRouter} from "../../services/withRouter";
import {TabSprintDetails} from "./TabSprintDetails";
import GET_ALL_PRODUCT_AND_TAGS_AND_RELEASE_AND_SPRINT from "../../querys/queryproductAndTagsAndReleaseAndSprint";
import {InsertReleaseProduct} from "./InsertReleaseProduct";

function attachProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class SprintComponentPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0,
            openNewRelease: false,
            productId: null
        };
    }

    async refreshCacheSprint(cache, data, idSprint) {
        // TODO: SOMENTE FRAGMENTO CACHEADO, getString não
        // let {getSprint} = cache.readQuery({
        //     query: GET_ALL_PRODUCT_AND_TAGS_AND_RELEASE_AND_SPRINT,
        //     variables: {idSprint}
        // });
        // getSprint = {...getSprint, productReleases: [...getSprint.productReleases, data.createProductRelease]};
        // cache.writeQuery({query: GET_ALL_PRODUCT_AND_TAGS_AND_RELEASE_AND_SPRINT, data: {getSprint}, variables: {idSprint}});
        return null;
    }

    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <Query query={GET_ALL_PRODUCT_AND_TAGS_AND_RELEASE_AND_SPRINT}
                               variables={{idSprint: this.props.params.idSprint}}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    let beginDate = new Date(parseInt(data.getSprint.begin));
                                    beginDate = beginDate.toLocaleDateString('pt-BR', {timeZone: 'UTC'});
                                    let endDate = new Date(parseInt(data.getSprint.end));
                                    endDate = endDate.toLocaleDateString('pt-BR', {timeZone: 'UTC'});
                                    return (
                                        <>
                                            <Typography variant="h4" component="h5" textAlign={"center"}>
                                                Sprint {data.getSprint.name} - {data.getSprint.product.name}
                                            </Typography>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Início: {beginDate}
                                            </Typography>
                                            <br/>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Fim: {endDate}
                                            </Typography>
                                            <br/>
                                            <Tabs onChange={(event, newValue) => this.setState({indexTab: newValue})}
                                                  value={this.state.indexTab}>
                                                <Tab label="Releases do Produto [PROD]" {...attachProps(0)} />
                                                <Tab label="Candidato de releases [HM]" {...attachProps(1)} />
                                                <Tab label="Versões [DEV]" {...attachProps(2)} />
                                            </Tabs>
                                            <TabSprintDetails link={'product/releases'} keyTag={'rel'}
                                                              current={this.state.indexTab}
                                                              reference={null}
                                                              index={0} newItem={() => {
                                                this.setState({openNewRelease: true})
                                            }}
                                                              data={data.getSprint.productReleases}/>
                                            <TabSprintDetails link={'releaseCandidate'} keyTag={'releaseCandidate'}
                                                              current={this.state.indexTab}
                                                              index={1} reference={data.getSprint.id}
                                                              environment={'Homologação'}
                                                              data={data.getSprint.releaseCandidate}/>
                                            <TabSprintDetails link={'tags'} keyTag={'tag'} current={this.state.indexTab}
                                                              index={2} environment={'Desenvolvimento'}
                                                              reference={data.getSprint.id}
                                                              data={data.getSprint.tags}/>
                                            <Modal
                                                open={this.state.openNewRelease}
                                                onClose={() => this.setState({openNewRelease: false})}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <InsertReleaseProduct refresh={this.refreshCacheSprint}
                                                                      idSprint={this.props.params.idSprint}
                                                                      close={() => {
                                                                          //TODO: remover após resolver cache
                                                                          window.location.reload();
                                                                          this.setState({openNewRelease: false})
                                                                      }}/>
                                            </Modal>
                                        </>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const SprintComponent = withRouter(SprintComponentPure);
