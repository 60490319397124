import {gql} from "@apollo/client";

const GET_RELEASE = gql`
    query getRelease($idRelease: ID!){
        getRelease(id: $idRelease) {
            name
            description
            created_at
            productRelease {
                id
                name
            }
            module {
                id
                name
                product {
                    id
                    name
                }
            }
        }
    }
`;

export default GET_RELEASE;
