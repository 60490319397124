import {gql} from "@apollo/client";

const CREATE_AUTH = gql`
    mutation createAuth($username: String!, $password: String!){
        createToken(input: {
            email: $username
            password: $password
        }){
            token
            user{
                role
            }
        }
    }
`;

export default CREATE_AUTH;
