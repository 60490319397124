import {gql} from "@apollo/client";

const GET_ALL_MODULES_AND_SPRINT = gql`
    query getAllModulesAndSprint($idProduct: ID!){
        getProduct(id: $idProduct) {
            name
            modules{
                id
                name
            }
            sprints {
                id
                name
            }
        }
    }
`;

export default GET_ALL_MODULES_AND_SPRINT;
