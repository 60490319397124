import React from 'react';
import {FormControl, Select, InputLabel} from "@mui/material";

const SelectComponent = (props) => {

    return (
        <FormControl fullWidth={props.fullWidth || true} style={{margin: '10px', ...props.style}}>
            <InputLabel id={props.name}>{props.label || "Selecione um item"}</InputLabel>
            <Select
                {...props?.register ? props.register(props.name) : null}
                id="outlined-basic"
                {...props}
                label={props.label || "Selecione um item"}
                fullWidth={props.fullWidth || true}
            >
                {props.children}
            </Select>
         </FormControl>
    );
};

export {SelectComponent};
