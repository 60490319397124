import React from 'react';
import { useForm } from "react-hook-form";
import {TextFieldComponent} from "../../components/TextFieldComponent";
import {ButtonComponent} from "../../components/ButtonComponent";

const FormLogin = ({sendData, method}) => {
    const {register, handleSubmit} = useForm();
    const onSubmit = data => sendData(method, {username: data.email, password: data.password});

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldComponent register={register} name='email' label="Usuário"/>
            <TextFieldComponent register={register} name='password' label="Senha" type={'password'}/>
            <ButtonComponent type={'submit'}>Entrar</ButtonComponent>
        </form>
    );
};

export {FormLogin};
