import React from 'react';
import {Box, FormHelperText, Typography} from "@mui/material";
import {TextFieldComponent} from "../../components/TextFieldComponent";
import {ButtonComponent} from "../../components/ButtonComponent";
import {useForm} from "react-hook-form";
import {LoadingSend} from "../../components/LoadingSendComponent";
import {Mutation} from "@apollo/client/react/components";
import CREATE_MODULE from "../../querys/createModule";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const InsertModule = ({method, close, refresh, idProduct}) => {
    const {register, handleSubmit} = useForm();

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Cadastro de módulo
            </Typography>
            <Mutation
                mutation={CREATE_MODULE}
                update={(cache, {data}) => refresh(cache, data, idProduct)}
            >
                {(insertModule, {data, loading, error}) => {
                    const onSubmit = data => {
                        return insertModule({
                            variables: {
                                name: data.name,
                                productId: idProduct
                            }
                        })
                    };
                    if (loading) {
                        return <LoadingSend width={300} heigth={300}/>
                    } else if (error) {
                        return (
                            <>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <TextFieldComponent register={register} name='name' label="Nome"/>
                                    <ButtonComponent type={'submit'}>Cadastrar</ButtonComponent>
                                </form>
                                <FormHelperText error={true} style={{textAlign: "center"}}>
                                    {error.message}
                                </FormHelperText>
                            </>
                        )
                    } else if (data) {
                        return (
                            <>
                                Cadastrado com sucesso
                                <br/> <br/>
                                <ButtonComponent onClick={close}>Fechar</ButtonComponent>
                            </>
                        )
                    } else {
                        return <form onSubmit={handleSubmit(onSubmit)}>
                            <TextFieldComponent register={register} name='name' label="Nome"/>
                            <ButtonComponent type={'submit'}>Cadastrar</ButtonComponent>
                        </form>
                    }

                }}
            </Mutation>
        </Box>
    );
};

export {InsertModule};
