import React from "react";
import {MenuComponent} from "../../components/MenuComponent";
import {CardComponent} from "../../components/CardComponent";
import {Query} from "@apollo/client/react/components";
import {
    Box,
    FormHelperText, Modal,
    Skeleton,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {withRouter} from "../../services/withRouter";
import GET_ALL_MODULES_AND_SPRINT from "../../querys/queryModulesAndSprint";
import {TabProductDetails} from "./TabProductDetails";
import {InsertModule} from "./InsertModule";
import {InsertSprint} from "./InsertSprint";
import storageData from "../../services/storageData";
import {TabPermissionsData} from "./TabPermissionsDetails";
import {InsertPermission} from "./InsertPermission";

function attachProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class ProductComponentPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0,
            openModule: false,
            openSprint: false,
            openPermission: false,
            role: storageData.getData('role')
        };
    }

    refreshCacheModule(cache, data, idProduct) {
        let {getProduct} = cache.readQuery({
            query: GET_ALL_MODULES_AND_SPRINT,
            variables: {idProduct}
        });
        getProduct = {...getProduct, modules: [...getProduct.modules, data.createModule]};
        cache.writeQuery({query: GET_ALL_MODULES_AND_SPRINT, data: {getProduct}, variables: {idProduct}});
        return null;
    }

    refreshCacheSprint(cache, data, idProduct) {
        let cacheData = cache.readQuery({
            query: GET_ALL_MODULES_AND_SPRINT,
            variables: {idProduct}
        });
        if(cacheData?.getProduct) {
            let getProduct = {...cacheData.getProduct, sprints: [
                ...cacheData.getProduct.sprints, data.createSprint]};
            cache.writeQuery({query: GET_ALL_MODULES_AND_SPRINT, data: {getProduct},
                variables: {idProduct}});
        }
        return null;
    }


    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <Modal
                            open={this.state.openModule}
                            onClose={() => this.setState({openModule: false})}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <InsertModule refresh={this.refreshCacheModule} idProduct={this.props.params.idProduct}
                                          close={() => this.setState({openModule: false})}/>
                        </Modal>

                        <Modal
                            open={this.state.openSprint}
                            onClose={() => this.setState({openSprint: false})}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <InsertSprint refresh={this.refreshCacheSprint} idProduct={this.props.params.idProduct}
                                          close={() => window.location.reload()}/>
                        </Modal>

                        <Modal
                            open={this.state.openPermission}
                            onClose={() => this.setState({openPermission: false})}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <InsertPermission idProduct={this.props.params.idProduct}
                                          close={() => window.location.reload()}/>
                        </Modal>
                        <Query query={GET_ALL_MODULES_AND_SPRINT} variables={{idProduct: this.props.params.idProduct}}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    return (
                                        <>
                                            <Typography variant="h4" component="h5" textAlign={"center"}>
                                                {data.getProduct.name}
                                            </Typography>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                <b>ID:</b> {this.props.params.idProduct}
                                            </Typography>
                                            <Tabs onChange={(event, newValue) => this.setState({indexTab: newValue})}
                                                  value={this.state.indexTab}>
                                                <Tab label="Sprints" {...attachProps(0)} />
                                                {this.state.role === 'ADMIN' && (
                                                    <Tab label="Pessoas Autorizadas" {...attachProps(1)} />
                                                )}
                                                <Tab label="Módulos" {...attachProps(2)} />
                                            </Tabs>
                                            <TabProductDetails link={'sprints'} keyTag={'spri'}
                                                               current={this.state.indexTab} index={0}
                                                               newItem={() => this.setState({openSprint: true})}
                                                               data={data.getProduct.sprints}/>
                                            <TabProductDetails link={'modules'} keyTag={'mod'}
                                                               current={this.state.indexTab} index={this.state.role === 'ADMIN' ? 2 : 1}
                                                               newItem={() => this.setState({openModule: true})}
                                                               data={data.getProduct.modules}/>
                                            {this.state.role === 'ADMIN' && (
                                                <TabPermissionsData link={'permissions'} keyTag={'per'}
                                                                    current={this.state.indexTab} index={1}
                                                                    newItem={() => this.setState({openPermission: true})}
                                                                    idProduct={this.props.params.idProduct} />
                                            )}


                                        </>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const ProductComponent = withRouter(ProductComponentPure);
