import {gql} from "@apollo/client";

const GET_PERMISSIONS_FROM_PRODUCT = gql`
    query getPermissionsFromProduct($idProduct: ID!){
        getProduct(id: $idProduct) {
            permissions{
                id
                access
                user{
                    id
                    email
                }
            }
        }
    }
`;

export default GET_PERMISSIONS_FROM_PRODUCT;
