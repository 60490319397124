import {gql} from "@apollo/client";

const GET_TAG = gql`
    query getTag($idTag: ID!){
        getTag(id: $idTag) {
            name
            created_at
            description
            sprint{
                id
                name
            }
            module{
                id
                name
                product{
                    id
                    name
                }
            }
        }
    }
`;

export default GET_TAG;
