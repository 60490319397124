import React from 'react';
import {Button} from "@mui/material";

const ButtonComponent = (props) => {
    return (
         <Button {...props} variant="contained" fullWidth={true} style={styles.button} 
          />
    );
};

const styles = {
    button: {
        margin: '10px'
    }
}

export {ButtonComponent};
