import React from 'react';
import {LinkCustomComponent} from "./LinkCustomComponent";

const LogoComponent = ({width, heigth}) => {
    return (
        <LinkCustomComponent to={`/`}>
            <img src={'/logo-coopersystem.png'} width={width} height={heigth}/>
        </LinkCustomComponent>
    );
};


export {LogoComponent};
