import React from "react";
import {MenuComponent} from "../components/MenuComponent";
import {CardComponent} from "../components/CardComponent";
import {Query} from "@apollo/client/react/components";
import {
    Box, Chip,
    FormHelperText,
    Skeleton,
    Typography
} from "@mui/material";
import {withRouter} from "../services/withRouter";
import GET_TAG from "../querys/queryTag";
import {LinkCustomComponent} from "../components/LinkCustomComponent";
import * as emoji from "node-emoji";

import printService from "../services/printService";

class TagComponentPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0
        };
    }

    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <Query query={GET_TAG}
                               variables={{idTag: this.props.params.idTag}}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    let createdAt = new Date(parseInt(data.getTag.created_at));
                                    createdAt = createdAt.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

                                    let description = 'Sem descrição';
                                    if (data.getTag.description) {
                                        description = data.getTag.description.replace(/\*/g, "<br />");
                                        description = emoji.emojify(description);
                                    }

                                    return (
                                        <>
                                            <Typography variant="h4" component="h5" textAlign={"left"}>
                                                Versão {data.getTag.name} - {data.getTag.module.name} - {data.getTag.module.product.name}
                                            </Typography>
                                            <br/>
                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Criado em: {createdAt} -
                                                <LinkCustomComponent to={'./'}
                                                                     onClick={() => printService.print(
                                                                         `Versão ${data.getTag.name} - ${data.getTag.module.name} - ${data.getTag.module.product.name}`,
                                                                         description)}>
                                                    Imprimir
                                                </LinkCustomComponent>
                                            </Typography>
                                            <br/>
                                            <div style={{backgroundColor: '#F1F3F4', padding: 10}}>
                                                <Typography variant="subtitle1" component="subtitle2"
                                                            textAlign={"left"}>
                                                    <td dangerouslySetInnerHTML={{__html: description}}/>
                                                </Typography>
                                            </div>

                                            <br/><br/>

                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Sprint: <LinkCustomComponent
                                                to={`/sprints/${data.getTag.sprint.id}`}>
                                                <Chip clickable={true} label={data.getTag.sprint.name} color="primary"/>
                                            </LinkCustomComponent>
                                            </Typography>

                                            <br/>

                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Módulo: <LinkCustomComponent
                                                to={`/modules/${data.getTag.module.id}`}>
                                                <Chip clickable={true} label={data.getTag.module.name} color="primary"/>
                                            </LinkCustomComponent>
                                            </Typography>

                                            <br/>

                                            <Typography variant="subtitle1" component="subtitle2" textAlign={"left"}>
                                                Produto:
                                                <LinkCustomComponent
                                                    to={`/products/${data.getTag.module.product.id}`}>
                                                    <Chip clickable={true} label={data.getTag.module.product.name}
                                                          color="primary"/>
                                                </LinkCustomComponent>
                                            </Typography>
                                        </>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const TagComponent = withRouter(TagComponentPure);
