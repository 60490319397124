import {gql} from "@apollo/client";

const CREATE_RELEASE = gql`
    mutation createProductRelease($name: String!, $sprintId: ID!, $releases: [ID!]){
        createProductRelease(input: {
            name: $name
            sprint_id: $sprintId
            releases: $releases
        }){
            id
            name
            description
        }
    }
`;

export default CREATE_RELEASE;
