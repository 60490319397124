import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../lotties/loading-data.json';

const LoadingSend = ({width, heigth}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Lottie options={defaultOptions}
                height={width}
                width={heigth}
                isStopped={false}
                isPaused={false}/>
    );
};


export {LoadingSend};
