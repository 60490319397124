import {gql} from "@apollo/client";

const GET_ALL_PRODUCTS = gql`
    query getAllProducts($filterType: projectTypeOptions){
        getAllProducts(filterType: $filterType){
            id
            name
        }
    }
`;

export default GET_ALL_PRODUCTS;
