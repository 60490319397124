import {gql} from "@apollo/client";

const GET_ALL_MODULES_AND_RELEASE_AND_COMMITS = gql`
    query getAllModulesAndReleases($idSprint: ID!){
        getSprint(id: $idSprint) {
            name
            begin
            end
            product{
                name
            }
            releaseCandidate{
                id
                name
                description
                module {
                    name
                }
            }
            tags{
                id
                name
                description
                module {
                    name
                }
            }
        }
    }
`;

export default GET_ALL_MODULES_AND_RELEASE_AND_COMMITS;
