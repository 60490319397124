import React from 'react';
import {Box, FormHelperText, Typography, MenuItem} from "@mui/material";
import {TextFieldComponent} from "../../components/TextFieldComponent";
import {ButtonComponent} from "../../components/ButtonComponent";
import {useForm} from "react-hook-form";
import {LoadingSend} from "../../components/LoadingSendComponent";
import {Mutation} from "@apollo/client/react/components";
import CREATE_USER from "../../querys/createUser";
import {SelectComponent} from "../../components/SelectComponent"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const InsertUser = ({method, close, refresh}) => {
    const {register, handleSubmit, watch} = useForm();
    const email = watch("email", "")
    const permission = watch("permission", "")

    return (
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Cadastro de usuário
            </Typography>
            <Mutation
                mutation={CREATE_USER}
                update={(cache, {data}) => refresh(cache, data)}
            >
                {(createUser, {data, loading, error}) => {
                    const onSubmit = data => {
                        return createUser({
                            variables: {
                                email: data.email,
                                role: data.permission
                            }
                        })
                    };
                    if (loading) {
                        return <LoadingSend width={300} heigth={300}/>
                    } else if (error) {
                        return (
                            <>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <TextFieldComponent register={register} name='email' label="Usuário"/>
                                    <SelectComponent label='Tipo de usuário' register={register} 
                                        name='permission' >
                                        <MenuItem value={'ADMIN'}>Administrador</MenuItem>
                                        <MenuItem value={'USER'}>Usuário</MenuItem>
                                    </SelectComponent>
                                    <ButtonComponent type={'submit'}
                                    disabled={email == "" || permission == ""}>Cadastrar</ButtonComponent>
                                </form>
                                <FormHelperText error={true} style={{textAlign: "center"}}>
                                    {error.message}
                                </FormHelperText>
                            </>
                        )
                    } else if (data) {
                        return (
                            <>
                                Cadastrado com sucesso
                                <br/> <br/>
                                <ButtonComponent onClick={close}>Fechar</ButtonComponent>
                            </>
                        )
                    } else {
                        return <form onSubmit={handleSubmit(onSubmit)}>
                            <TextFieldComponent register={register} name='email' label="Usuário"/>
                            <SelectComponent register={register} name='permission'>
                                <MenuItem value={'ADMIN'}>Administrador</MenuItem>
                                <MenuItem value={'USER'}>Usuário</MenuItem>
                            </SelectComponent>
                            <ButtonComponent type={'submit'}
                                    disabled={email == "" || permission == ""}>Cadastrar</ButtonComponent>
                        </form>
                    }

                }}
            </Mutation>
        </Box>
    );
};

export {InsertUser};
