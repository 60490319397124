import {gql} from "@apollo/client";

const CREATE_USER = gql`
    mutation createUser($email: String!, $role: userRole!){
        createUser(input: {
            email: $email
            role: $role
        }){
            id
            email
        }
    }
`;

export default CREATE_USER;
