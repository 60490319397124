import {gql} from "@apollo/client";

const CREATE_MODULE = gql`
    mutation createModule($name: String!, $productId: ID!){
        createModule(input: {
            name: $name
            product_id: $productId
        }){
            id
            name
        }
    }
`;

export default CREATE_MODULE;
