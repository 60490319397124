import {gql} from "@apollo/client";

const SEARCH_PROJECT = gql`
    query getProjects($name: String!){
        getProjects(name: $name) {
            id
            title
        }
    }
`;

export default SEARCH_PROJECT;
