import React from "react";
import {MenuComponent} from "../../components/MenuComponent";
import {CardComponent} from "../../components/CardComponent";
import {Query, Mutation} from "@apollo/client/react/components";
import {
    Box,
    Button,
    FormHelperText,
    List,
    ListItem,
    ListItemButton,
    ListItemText, Modal,
    Skeleton,
    Typography,
    Chip
} from "@mui/material";
import {withRouter} from "../../services/withRouter";
import GET_ALL_USERS from "../../querys/queryUsers";
import {InsertUser} from "./InsertUser";
import {LoadingSend} from "../../components/LoadingSendComponent";
import DELETE_USER from "../../querys/deleteUser";


class UserListComponentPure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    refreshCache(cache, data, deleteData = null) {
        let {getAllUsers} = cache.readQuery({query: GET_ALL_USERS});
        if (deleteData) {
            getAllUsers = getAllUsers.filter((data, index) => index !== deleteData)
        } else {
            getAllUsers = [...getAllUsers, data];
        }
        cache.writeQuery({query: GET_ALL_USERS, data: {getAllUsers}});
        return null;
    }

    render() {
        return (
            <>
                <MenuComponent/>
                <header className="content">
                    <CardComponent style={{width: '800px'}}>
                        <Typography variant="h4" component="h5" textAlign={"left"}>
                            Usuários
                            <Button onClick={() => this.setState({open: true})} variant="text">Cadastrar </Button>
                        </Typography>
                        <Modal
                            open={this.state.open}
                            onClose={() => this.setState({open: false})}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <InsertUser refresh={this.refreshCache} close={() => this.setState({open: false})}/>
                        </Modal>
                        <Query query={GET_ALL_USERS}>
                            {({data, loading, error}) => {
                                if (loading) {
                                    return (
                                        <Box sx={{width: '800px'}}>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </Box>
                                    )
                                } else if (error) {
                                    return (
                                        <>
                                            <FormHelperText error={true} style={{textAlign: "center"}}>
                                                {error.message}
                                            </FormHelperText>

                                        </>
                                    )
                                } else if (data) {
                                    return (
                                        <List>
                                            {
                                                data.getAllUsers.map((user, userIndex) => {
                                                    return (
                                                        <Mutation
                                                            mutation={DELETE_USER}
                                                            update={(cache, {dataDelete}) => this.refreshCache(cache, dataDelete, userIndex)}
                                                        >
                                                            {(deleteUser, {data, loading, error}) => {
                                                                if (loading) {
                                                                    return <LoadingSend width={300} heigth={300}/>
                                                                } else if (error) {
                                                                    return (
                                                                        <>
                                                                            <FormHelperText error={true}
                                                                                            style={{textAlign: "center"}}>
                                                                                Erro ao remover usuário
                                                                                {error.message}
                                                                            </FormHelperText>

                                                                            <ListItem key={user.id} disablePadding>
                                                                                <ListItemButton>
                                                                                    <Chip label={user.role} />
                                                                                    <ListItemText style={{marginLeft: 10}} primary={user.email}/>
                                                                                    <Button
                                                                                        onClick={() => this.setState({open: true})}
                                                                                        variant="text">Excluir</Button>
                                                                                </ListItemButton>
                                                                            </ListItem>
                                                                        </>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <ListItem key={user.id} disablePadding>
                                                                            <ListItemButton>
                                                                                <Chip label={user.role} />
                                                                                <ListItemText style={{marginLeft: 10}} primary={user.email}/>
                                                                                <Button
                                                                                    onClick={() => deleteUser({
                                                                                        variables: {
                                                                                            idUser: user.id
                                                                                        }
                                                                                    })}
                                                                                    variant="text">Excluir</Button>
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                    )
                                                                }
                                                            }}
                                                        </Mutation>
                                                    )
                                                })
                                            }
                                        </List>
                                    )
                                }
                            }}
                        </Query>
                    </CardComponent>
                </header>
            </>
        );
    }
}

export const UserListComponent = withRouter(UserListComponentPure);
