import React, {useEffect, useState} from 'react';
import {LogoComponent} from "./LogoComponent";
import {OptionComponent} from "./OptionComponent";
import authService from "../services/authService"
import {useNavigate} from "react-router-dom";
import storageData from "../services/storageData";

const MenuComponent = (props) => {
    let navigate = useNavigate();
    const [role, setRole] = useState('USER')

    useEffect(() => {
        const role = storageData.getData('role')
        setRole(role)
    }, [])

    return (
        <div style={styles.menu}>
            <div style={styles.off}>
                {role === 'ADMIN' && (
                    <OptionComponent onClick={() => {navigate(`/users`)}}>
                        Usuários
                    </OptionComponent>
                )}
                <OptionComponent onClick={() => authService.removeAuthorization()}>Sair</OptionComponent>
            </div>

            <LogoComponent width={140}/>
        </div>
    );

};

const styles = {
    menu: {
        margin: '10px'
    },
    off: {
        float: 'right',
    }
}

export {MenuComponent};
