import React from 'react';
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {LinkCustomComponent} from "../../components/LinkCustomComponent";

const TabProductDetails = ({data, current, index, keyTag, link, newItem}) => {
    data = [ ...data, {set: null}];
    return (
        <List role="tabpanel"
              hidden={current !== index}
              key={`${index}${keyTag}`}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}>
            {
                current === index &&
                data.map(module => {
                    if (module.set !== null) {
                        return (
                            <LinkCustomComponent key={`${module.id}LinkCustomComponent`} to={`/${link}/${module.id}`}>
                                <ListItem key={module.id} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={module.name}/>
                                    </ListItemButton>
                                </ListItem>
                            </LinkCustomComponent>
                        )
                    } else {
                        return (
                            <ListItem key={'newModule'} disablePadding onClick={newItem}>
                                <ListItemButton>
                                    <ListItemText primary={'Novo'} />
                                </ListItemButton>
                            </ListItem>
                        )
                    }

                })
            }
        </List>
    );
};

export {TabProductDetails};
