import './App.css';
import React from "react";

import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {AuthComponent} from "./screens/Auth/Auth";
import {ProductListComponent} from "./screens/ListProduct/ListProducts";
import {ProductComponent} from "./screens/Product/Product";
import {ModulesComponent} from "./screens/Module/Module";
import {SprintComponent} from "./screens/Sprint/Sprint";
import {TagComponent} from "./screens/Tag";
import {ReleaseComponent} from "./screens/Release";
import {ProductReleaseComponent} from "./screens/ProductRelease";
import storageData from "./services/storageData";
import {UserListComponent} from "./screens/users/ListUsers";
import {DetailsCommitComponent} from "./screens/DetailsCommit";
import {ReleaseCandidateComponent} from "./screens/ReleaseCandidate";

function App() {
    const token = storageData.getData('token');

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={token ? <Navigate to="/products" /> : <Navigate to="/auth" />} />
                <Route path="/auth" element={<AuthComponent />}/>
                <Route path="/users" element={<UserListComponent />}/>
                <Route path="/sprints/:idSprint/commits/:environmentDev" element={<DetailsCommitComponent />} />
                <Route path="/products" element={<ProductListComponent />}/>
                <Route path="/products/:idProduct" element={<ProductComponent />}/>
                <Route path="/modules/:idModule" element={<ModulesComponent />}/>
                <Route path="/sprints/:idSprint" element={<SprintComponent />}/>
                <Route path="/tags/:idTag" element={<TagComponent />}/>
                <Route path="/releaseCandidate/:idCandidateRelease" element={<ReleaseCandidateComponent />}/>
                <Route path="/releases/:idRelease" element={<ReleaseComponent />}/>
                <Route path="/product/releases/:idProductRelease" element={<ProductReleaseComponent />}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
